.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 16px;
    box-sizing: border-box;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 24px 0 0;
    box-sizing: border-box;
    text-align: justify;
}