@import '/src/tokens.scss';

.main {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
}

.content_wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    min-height: 280px;
    position: relative;
}

.info_container {
    width: 100%;
    box-sizing: border-box;
    padding: 0 36px;
}

.footerBtn {
    background: transparent;
    color: var(--color-white);
    border: none;
    font-family: 'Geologica', sans-serif;
    font-size: 24px;
    cursor: pointer;
    @media (min-width: 1050px) {
        font-size: 40px;
    }
}

.footerImg {
    position: absolute;
    right: 0;
    bottom: 0;
    @media (max-width: 1049px) {
        height: 300px;
    }
}

.dialog_container {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    position: relative;
}

.closeX {
    margin-left: 100%;
    position: sticky;
    cursor: pointer;
    top: 10px;
    font-family: 'Geologica';
    font-size: 18px;
    border: none;
    background: transparent;
    @media (min-width: 1050px) {
        font-size: 24px;
    }
}

.dialog {
    width: 80vw;
    height: 80vh;
}

dialog::backdrop {
    background: rgba($color: #000000, $alpha: 0.7);
}