@import '/src/tokens.scss';

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-bottom: 30px;
    height: 592px;
    width: 435px;
    &:nth-child(odd) {
        margin-top: 40px;
    }
}

.img {
    position: relative;
    width: 35%;
    margin-bottom: -70px;
    z-index: 2;
}

.container {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    border-radius: $border-radius;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
}