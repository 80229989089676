@import '/src/tokens.scss';

.main {
    position: relative;
    background-color: var(--color-dark-blue);
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mainBg {
    position: absolute;
    top: 20px;
    left: 0;

    @media (max-width: 1049px) {
        width: calc(100% - 40px);
        max-height: 700px;
        max-width: 450px;
    }

    @media (min-width: 1050px) {
        right: unset;
        left: 30%;
        transform: translateX(-50%);
    }
}

.wrapper {
    max-width: $max-content-width;
}

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 1050px) {
        align-items: flex-start;
        justify-content: center;
        flex-direction: row;
    }
}

.mainImage {
    width: 230px;
    height: 230px;
    margin-top: -14px;
    @media (min-width: 1050px) {
        margin-top: 88px;
        width: auto;
        height: 835px;        
    }
}

.content_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 0 40px 34px;
    margin-bottom: 80px;
    @media (min-width: 1050px) {
        align-items: flex-start;
        padding: 0 30px 34px;
        margin-bottom: 0;
    }
}

.logo {
    margin: -20px 0 34px;
    max-width: 178px;
    max-height: 97px;
    @media (min-width: 1050px) {
        margin: 0 0 34px;
        max-width: 480px;
        max-height: 263px;
    }
}

.paragraph {
    margin: 4px 0;
    text-align: justify;
    font-family: 'Geologica', sans-serif;
    font-weight: 100;
    font-size: 16px;
    line-height: 135%;
    align-self: flex-start;
    @media (min-width: 1050px) {
        font-size: 22px;
    }
}

.subText{
    margin: 24px 0;
    font-family: 'Geologica', sans-serif;
    font-weight: 600;
    font-size: 19px;
    line-height: 135%;
    @media (min-width: 1050px) {
        font-size: 31px;
    }
}

.contactBtn {
    margin: 18px 0 0;
    width: 355px;
    height: 82px;
    border-radius: 30px;
    background-color: var(--color-light-green);
    cursor: pointer;
    border-style: none;

    color: var(--color-white);
    font-family: 'Geologica', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 135%;

    @media (min-width: 1050px) {
        font-size: 31px;
    }
}
