@import '/src/tokens.scss';

.main {
    width: 100%;
    margin: 0 0 -200px;
    padding: 0px 0 10px;
    background-color: var(--color-white);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    margin: 40px 0 0;
    font-size: 30px;
    font-family: 'Mitr', sans-serif;
    font-weight: 600;
    color: var(--color-dark-blue);
    @media (min-width: 1600px) {
        margin: 0 0 20px;
        font-size: 45px;
    }
}

.text {
    margin: 0 0 20px;
    font-size: 20px;
    font-family: 'Mitr', sans-serif;
    font-weight: 500;
    color: var(--color-dark-blue);
    @media (min-width: 1600px) {
        margin: 0 0 40px;
        font-size: 26px;
    }
}