@import '/src/tokens.scss';

.main {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box;
    @media (min-width: 1600px) {
        display: none;
    }
}

.carousel {
    color: #000000;
    width: 100%;
    overflow-x: hidden;
    display: flex;
    justify-content: space-between;
    scroll-behavior: smooth;
    scroll-snap-align: center;
}

.slide {
    width: 100%;
    display: flex;
    justify-content: center;
    min-width: 100vw;
    user-select: none;
}

.dots_container {
    display: flex;
    justify-content: center;
    gap: 15px
}

.dots {
    appearance: none;
    margin: 0;
    background-color: var(--color-grey);
    width: 18px;
    height: 18px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    &:checked {
        background-color: var(--color-light-green);
    }
}