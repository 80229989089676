@import '/src/tokens.scss';

.main {
    color: var(--color-white);
    padding: 0;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.container {
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
}