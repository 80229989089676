
$border-radius: 8px;
$max-content-width: 1350px;
$max-screen-width: 1440px;

.main {
    --color-red: #F25757;
    --color-yellow: #FFC239;
    --color-cyan: #52AFD8;
    --color-grey: #D3E1EE;
    --color-dark-blue: #040C39;
    --color-dark-green: #037171;
    --color-light-green: #5EE6AE;
    --color-green: #30C680;
    --color-white: #FFFFFF;
}