@import '/src/tokens.scss';

.main {
    width: 100%;
    background-color: var(--color-white);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.title {
    margin: 40px 0 0;
    font-size: 30px;
    font-family: 'Mitr', sans-serif;
    font-weight: 600;
    color: var(--color-dark-blue);
    @media (min-width: 1600px) {
        margin: 0 0 40px;
        font-size: 45px;
    }
}

.container {
    display: none;
    color: #000000;
    justify-content: space-between;
    gap: 80px;
    @media (min-width: 1600px) {
        display: flex;
    }
}

.content__Wrapper {
    flex-grow: 1;
    padding: 100px 20px 0;
    box-sizing: border-box;
    color: var(--color-dark-blue);
}

.content__Title {
    width: 100%;
    font-family: 'Mitr', sans-serif;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    line-height: 135%;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
}

ul {
    list-style: none;
}

.content__List {
    position: relative;
    font-family: 'Geologica', sans-serif;
    font-weight: 300;
    font-size: 24px;
    line-height: 210.5%;
}

.content__List::before {
    content: "";
    left: -14px;
    top: 20px;
    position: absolute;
    width: 5px;
    height: 10px;
    background: #30C680;
    border-radius: 2px;
}