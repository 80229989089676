@import '/src/tokens.scss';

.main {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    background-color: var(--color-dark-blue)
}

.container {
    margin-top: -60px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    margin-top: 60px;
    font-size: 30px;
    font-family: 'Mitr', sans-serif;
    font-weight: 600;
    color: var(--color-white);
    @media (min-width: 1050px) {
        margin-bottom: 100px;
        font-size: 45px;
    }
}

.content_grid {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (min-width: 1050px) {
        display: grid;
        gap: 80px;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('/assets/svg/bgTailorSolutions.svg');
    }
}

.grid1 {
    grid-area: 1 / 1 / 2 / 2;
}

.grid2 {
    grid-area: 1 / 2 / 2 / 3;
}

.grid3 {
    grid-area: 2 / 1 / 3 / 2;
}

.grid4 {
    grid-area: 2 / 2 / 3 / 3;
}

.grid1, .grid2, .grid3, .grid4 {
    display: flex;
    flex-direction: column;
}

.grid1, .grid3 {
    @media (min-width: 1050px) {
        align-items: flex-end;
        .item {
            width: 400px;
        }
    }
}

.content__Title {
    width: 100%;
    font-family: 'Mitr', sans-serif;
    font-weight: 600;
    font-size: 19px;
    line-height: 97.5%;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    
    @media (min-width: 1050px) {
        margin-left: 25px;
        font-size: 45px;
        justify-content: flex-start;

    }
}

ul {
    list-style: none;
    margin-bottom: 0;
}

.content__List {
    display: flex;
    position: relative;
    font-family: 'Geologica', sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 210.5%;
    @media (min-width: 1050px) {
        font-size: 24px;
    }
}

.content__List::before {
    content: "";
    left: -14px;
    top: 13px;
    position: absolute;
    width: 4px;
    height: 8px;
    background: #30C680;
    border-radius: 2px;
    @media (min-width: 1050px) {
        width: 5px;
        height: 10px;
        top: 20px;
    }
}

.mobile_logo {
    display: flex;
    justify-content: center;
    @media (min-width: 1050px) {
        display: none;
    }
}

.mobile_banner {
    display: flex;
    justify-content: center;
    @media (min-width: 1050px) {
        display: none;
    }
}
