@import '/src/tokens.scss';

.main {
    width: 100%;
    padding: 0;
    background-color: var(--color-dark-blue);
}

.container {
    background-color: var(--bg-container-color);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.svgTop {
    background-color: var(--color-dark-blue);
}

.svgBottom {
    background-color: var(--color-white);
}