@import '/src/tokens.scss';

.header {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-top: 30px;
    padding-right: 5%;
    user-select: none;
    
    @media (max-width: 1350px) {
        padding-right: 48px;
    }
}

.header_content {
    background: transparent;
    border: none;
    position: relative;
    display: flex;
    align-items: center;
    color: var(--color-white);
    cursor: pointer;
        span {
            margin-right: 20px;
            font-family: 'Geologica', sans-serif;
            font-weight: 400;
            font-size: 25px;
            line-height: 135%;
        }
}

.dropdownLenguageOff {
    display: none;
}

.dropdownLenguageOn {
    display: flex;
    flex-direction: column;
    gap: 10px;
    top: 140%;
    left: -6px;
    position: absolute;
    z-index: 4;
    
    button {
        display: flex;
        align-items: center;
        color: var(--color-light-green);
        height: 30px;
        background-color: var(--color-dark-blue);
        border-radius: 4px;
        font-family: 'Geologica', sans-serif;
        font-size: 20px;
        border: none;
        cursor: pointer;
        span {
            justify-self: flex-start;
            text-align: start;
            margin: 0;
            width: 50px;
        }
    }
}

.flagImg {
    width: 30px;
    height: 20px;
    object-fit: cover;
}